$modalBgColor: $blue200;
$modalIconColor: $red400;
$iconBgColor: $yellow400;
$iconColor: $black;

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-modal {
  background-color: $modalBgColor;
  .card-title .icon {
    fill: $modalIconColor;
  }
  .card-action {
    margin-top: 20px;
  }
  .modal-message {
    @include fontStyle(p);
    letter-spacing: 0;
  }
}

.image-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: min(75vw, 75vh);
  height: min(75vw, 75vh);
  background: gradient('gray', 135deg);
  .asset-image {
    width: auto;
    max-width: 75vw;
    max-height: 75vh;
    border-radius: unset;
  }
}
.image-modal-icon-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $iconBgColor;
  border-radius: 32px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  right: -40px;
  top: -40px;
  .icon {
    fill: $iconColor;
  }
}

