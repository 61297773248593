@import '../utils/main';

$linkColor: $yellow400;
$homeBtnIconContainerBgColor: $white;

$cardActionDividerColor: $divider;
$signUpLinkAfterBgColor: $white;
$checkBoxBgColor: $blue400;
$checkBoxSelectedBgColor: $yellow400;
$checkBoxIconColor: $black;
$linkColor: $yellow400;

$gamesAndAuctionsBgColor: $blue800;
$arrowBtnBgStartColor: $blue800;
$arrowBtnBgEndColor: rgba($blue800, 0);
$btnTextColor: $white;

$introductionLineBgColor: gradient('eva', 90deg);

$introductionBgColor: $black;
$titleDecorationLineBgColor: $turquoise400;

$guideImgBtnBorderColor: rgba($turquoise400, 0.3);
$guideImgBtnBorderHoverColor: rgba($turquoise400, 0.5);

$guideImgBtnBgColor: $blue900;

$outerBorderColor: rgba($turquoise400, 0.3);
$cornerBorderColor: $turquoise400;

.sign-in-container,
.sign-up-container,
.forgot-password-container,
.sign-in-container.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link {
    color: $linkColor;
  }
}

.modal {
  .sign-links {
    display: none;
  }

  .sign-in-container {
    position: fixed;
    margin: unset;
    left: calc(50% - 180px);
    top: calc(50% - 180px);
  }
}

.forgot-password-card {
  .text {
    @include fontStyle(p);
    letter-spacing: 0px;
  }

  .input-outer {
    margin-top: 8px;
  }

  .card-action {
    margin-top: 16px;
  }
}

.sign-in-card,
.sign-up-card {
  margin-bottom: 16px;

  .input-label {
    display: flex;
    justify-content: space-between;
  }

  .text {
    @include fontStyle(600, 14px, 21px);
  }

  .create-account-container {
    display: flex;
    justify-content: center;

    .link {
      margin-left: 4px;
    }
  }

  .card-action {
    flex-direction: column;

    .btn {
      @include fontStyle(700, 15px, 20px);
    }

    &::after {
      content: '';
      margin-left: -32px;
      margin-top: 32px;
      margin-bottom: 32px;
      height: 1px;
      width: 100%;
      width: calc(100% + 64px);
      background-color: $cardActionDividerColor;
    }
  }
}

.modal .sign-in-card {
  margin: unset;
}

.check-box-container {
  display: flex;
  align-items: flex-start;
  line-height: 0;

  .icon-check-box {
    min-width: 16px;
    height: 16px;
    margin-right: 8px;
    background-color: $checkBoxBgColor;
    border-radius: 4px;
    cursor: pointer;

    .icon {
      display: none;
      fill: $checkBoxIconColor;
      width: 16px;
      height: 16px;
    }

    &.selected {
      background-color: $checkBoxSelectedBgColor;

      .icon {
        display: block;
      }
    }
  }

  .text-check-box {

    .text,
    .sub-line-text {
      @include fontStyle(400, 15px, 22px);
    }

    .link-text {
      color: $linkColor;
    }
  }
}

.to-sign-container {
  display: flex;
  justify-content: center;

  .text-to-sign-in,
  .link-to-sign-in {
    @include fontStyle(600, 14px, 19px);
  }

  .link-to-sign-in {
    margin-left: 4px;
    color: $linkColor;
  }
}

.sign-links {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 80px;

  .link-text {
    display: flex;
    align-items: center;
    color: $linkColor;
    @include fontStyle(600, 14px, 19px);

    &:not(:last-child)::after {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: $signUpLinkAfterBgColor;
      margin: 0 8px;
    }
  }
}

.check-email-container,
.change-email-container {
  display: flex;
  justify-content: center;
}

.check-email-card {
  width: 420px;
  margin: 120px 0;

  .card-title {
    .title-with-icon {
      display: flex;
      justify-content: center;
      margin-right: 4px;
    }

    .icon {
      fill: $linkColor;
    }

    .armor-email-img {
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
    }
  }

  .card-content {
    letter-spacing: 0px;

    .email {
      font-weight: 600;
    }

    .links-container {
      display: flex;
      flex-direction: column;
      margin: 20px 0;

      .links {
        display: flex;
      }

      .text {
        margin: 0 4px;
      }
    }

    .text {
      display: flex;
      margin-top: 20px;

      .link {
        margin-left: 4px;
      }
    }

    .link {
      font-weight: 600;
      color: $linkColor;
      cursor: pointer;
    }
  }
}

.change-email-card {
  margin: 80px 0;
  width: 368px;

  .input-outer {
    margin-bottom: 8px;
  }

  .email {
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.visitor-home-container {
  .title {
    display: flex;
    justify-content: center;

    .title-text {
      margin: 0 12px;
    }

    .decoration-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 43px;
    }

    .title-diamond {
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      background-color: $titleDecorationLineBgColor;
    }

    .title-line {
      width: 24px;
      height: 2px;
      background-color: $titleDecorationLineBgColor;
    }
  }
}

.home-container {
  justify-content: center;
  margin-bottom: 40px;
}

.home-video-container {
  width: 100%;

  .home-video-outer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 133.333%;

    .home-video {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.home-text {
  display: flex;
}

.home-btn {
  align-self: center;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 999em;
    margin-left: -15px;
    background-color: $homeBtnIconContainerBgColor;
  }

  .icon {
    font-size: 24px;
  }

  .btn-text {
    width: 75px;
    margin-left: 12px;
    @include fontStyle(400, 20px, 30px);
  }
}

.games-and-auctions {
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  background-color: $gamesAndAuctionsBgColor;

  .prev-btn-container,
  .next-btn-container,
  .prev-btn-container::after,
  .next-btn-container::before {
    display: flex;
    align-items: center;
    height: 186px;
    z-index: 50;
  }

  .prev-btn-container,
  .next-btn-container {
    position: relative;
  }

  .prev-btn-container::after,
  .next-btn-container::before {
    content: '';
    position: absolute;
  }

  .prev-btn-container::after {
    background: linear-gradient(90deg,
        $arrowBtnBgStartColor,
        $arrowBtnBgEndColor);
  }

  .next-btn-container::before {
    background: linear-gradient(270deg,
        $arrowBtnBgStartColor,
        $arrowBtnBgEndColor);
  }

  .prev-btn-outer,
  .next-btn-outer {
    height: 67.88px;
    min-width: 67.88px;
    z-index: 51;
  }

  .card {
    margin: 0 8px;
  }
}

.games-container,
.auctions-container {
  display: flex;
  flex-direction: column;
}

.auctions-subtitle,
.btn-more {
  align-self: center;
}

.games,
.auctions {
  display: flex;
  flex-direction: row;
}

.games {
  .card {
    padding: 8px;
  }

  .game-img {
    width: 256px;
    height: 144px;
    border-radius: 8px 8px 0px 0px;
  }

  .game-text {
    @include fontStyle(p);
  }
}

.auctions {

  .prev-btn-container,
  .prev-btn-container::after,
  .next-btn-container,
  .next-btn-container::before {
    height: 330px;
  }
}

.games-content,
.auctions-content {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.games-content::-webkit-scrollbar,
.auctions-content::-webkit-scrollbar {
  display: none;
}

.auctions-content {
  .auction-basic-content {
    min-width: 272px;
  }
}

.btn-more .MuiButton-label {
  color: $btnTextColor;
}

.introduction-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 40px;

  .outer-border {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }

  .agence-container,
  .explorer-container {
    padding-top: 40px;
  }
}

.introduction-title {
  @include fontStyle(h5);
  padding: 12px 0;
}

.introduction-paragraph {
  padding: 12px 0;
}

.introduction-background-outer,
.introduction-background,
.introduction-background-img {
  display: flex;
  align-self: center;
  position: absolute;
  height: 100%;
  overflow: hidden;
}

.introduction-background-outer {
  flex-direction: column;
  width: 100vw;
  z-index: 0;
}

.introduction-background {
  width: 100%;
  background-color: $introductionBgColor;
  opacity: 1;
  z-index: -1;
}

.introduction-background-img {
  width: auto;
  opacity: 0.1;
  z-index: 0;
}

.logo-outer-container {
  width: 100%;
}

.logo-outer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.logo-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .introduction-logo {
    width: 288px;
    height: 96px;
  }
}

.introduction-line {
  width: 40px;
  height: 2px;
  background: $introductionLineBgColor;
}

.introduction-btn {
  margin-top: 8px;
}

.guide-container {
  display: flex;
  flex-direction: column;
}

.guide-title {
  margin: 120px 0 40px 0;
}

.guide-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  .prev-btn-container,
  .next-btn-container {
    position: absolute;
    align-self: center;
    z-index: 50;

    .btn.btn-arrow.btn-arrow:after {
      border-color: $guideImgBtnBorderColor;
      background-color: $guideImgBtnBgColor;
    }
  }

  .prev-btn-container:hover,
  .next-btn-container:hover {
    .btn.btn-arrow.btn-arrow:after {
      border-color: $guideImgBtnBorderHoverColor;
      background-color: $guideImgBtnBgColor;
    }
  }
}

.guide-img-outer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.guide-content {
  display: flex;
  align-items: center;

  .guide-img {
    display: none;
    height: 100%;
    width: 100%;

    &.guide-fifth-img {
      width: 50%;
      cursor: pointer;
    }

    &.show {
      display: flex;
    }
  }
}

.btn.content-btn {
  margin-top: 8px;

  .MuiButton-label {
    color: $btnTextColor;
  }
}

.outer-border-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.outer-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $outerBorderColor;
  transform: translateX(0);
}

.outer-border-content {
  display: flex;
  justify-content: center;
  z-index: 50;
}

.corner {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 50;

  &.top {
    top: -2px;
    border-top: 2px solid $cornerBorderColor;
  }

  &.right {
    right: -2px;
    border-right: 2px solid $cornerBorderColor;
  }

  &.bottom {
    bottom: -2px;
    border-bottom: 2px solid $cornerBorderColor;
  }

  &.left {
    left: -2px;
    border-left: 2px solid $cornerBorderColor;
  }
}

.guide-container {
  .row {
    justify-content: center;
  }

  .guide-step {
    margin-top: 20px;
  }

  .guide-content {
    margin-top: 62px;
    margin-bottom: 40px;
  }

  .step-btn:not(:first-child)::before {
    width: 72px;
  }

  .content-title {
    margin-top: 16px;
    @include fontStyle(h6);
  }

  .content-paragraph {
    margin: 12px 0;
  }

  .content-btn {
    margin-top: 8px;
  }

  .guide-img-outer-container {
    width: 100%;
  }

  .prev-btn-container {
    left: 21px;
  }

  .next-btn-container {
    right: 21px;
  }
}

.isSignIn.isSignIn {
  display: none;
}

@include rwd(above, s) {
  .title-text {
    @include fontStyle(h5);
  }

  .home-container {
    .home-text {
      margin-top: 0;
      text-align: center;
      @include fontStyle(h4);
    }
  }

  .games-and-auctions {
    padding: 40px 0;

    .games-title {
      margin-bottom: 40px;
    }

    .auctions {
      margin-top: 40px;
    }

    .auctions-subtitle {
      margin-top: 12px;
      @include fontStyle(p);
    }

    .btn-more {
      margin: 40px 0;
    }

    .prev-btn-container,
    .next-btn-container,
    .prev-btn-container::after,
    .next-btn-container::before {
      min-width: 40px;
    }

    .prev-btn-container::after {
      transform: translate(40px, 0);
    }

    .next-btn-container::before {
      transform: translate(-40px, 0);
    }

    .prev-btn-outer,
    .next-btn-outer {
      display: none;
    }
  }

  .introduction-container {
    .row {
      justify-content: center;
    }
  }
}

@include rwd(above, m) {
  .home-container {
    .home-text {
      text-align: left;
      margin-top: 60px;
    }

    .home-btn {
      align-self: unset;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .games-and-auctions {
    .auctions-title {
      padding-top: 120px;
    }

    .prev-btn-container,
    .next-btn-container,
    .prev-btn-container::after,
    .next-btn-container::before {
      min-width: 80px;
    }

    .prev-btn-container::after {
      transform: translate(80px, 0);
    }

    .next-btn-container::before {
      transform: translate(-80px, 0);
    }

    .prev-btn-outer,
    .next-btn-outer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .prev-btn-outer {
      margin-left: 20px;
      margin-right: 0;
    }

    .next-btn-outer {
      margin-right: 0;
      margin-left: -7.88px;
    }
  }

  .guide-container {
    .guide-step {
      margin-top: 40px;
    }

    .guide-content {
      margin-bottom: 80px;
    }

    .step-btn:not(:first-child)::before {
      width: 120px;
    }
  }
}

@include rwd(above, l) {

  .title-text,
  .introduction-title {
    @include fontStyle(h4);
  }

  .home-container {
    .home-text {
      margin-top: 100px;
      @include fontStyle(h3);
    }

    .home-btn {
      margin-top: 24px;
    }
  }

  .games-and-auctions {
    padding: 60px 0;

    .games-title {
      margin-bottom: 60px;
    }

    .auctions-title {
      padding-top: 160px;
    }

    .auctions-subtitle {
      margin-top: 16px;
      @include fontStyle(h6);
    }

    .btn-more {
      margin: 60px 0;
    }

    .prev-btn-container,
    .next-btn-container,
    .prev-btn-container::after,
    .next-btn-container::before {
      min-width: 120px;
    }

    .prev-btn-container::after {
      transform: translate(120px, 0);
    }

    .next-btn-container::before {
      transform: translate(-120px, 0);
    }

    .prev-btn-outer {
      margin-left: 40px;
      margin-right: 12.12px;
    }

    .next-btn-outer {
      margin-right: 40px;
      margin-left: 12.12px;
    }
  }

  .introduction-container {

    .agence-container,
    .explorer-container {
      padding: 60px 0;
    }

    .introduction-title {
      padding-top: 0;
      padding-bottom: 12px;
    }

    .introduction-title,
    .introduction-line,
    .introduction-paragraph,
    .introduction-btn {
      transform: translateY(0);
      margin-left: 24px;
    }
  }

  .guide-container {
    .guide-title {
      margin-top: 120px;
    }

    .guide-content {
      margin-bottom: 120px;
    }

    .content-title {
      @include fontStyle(h5);
    }

    .guide-img-outer-container {
      width: calc(100% - 48px);
    }
  }
}

@include rwd(above, xl) {
  .home-container {
    .home-text {
      margin-top: 200px;
      @include fontStyle(h2);
    }

    .home-btn {
      margin-top: 40px;
    }
  }

  .games-and-auctions {

    .prev-btn-container,
    .next-btn-container,
    .prev-btn-container::after,
    .next-btn-container::before {
      min-width: 160px;
    }

    .prev-btn-container::after {
      transform: translate(160px, 0);
    }

    .next-btn-container::before {
      transform: translate(-160px, 0);
    }

    .prev-btn-outer {
      margin-left: 80px;
    }

    .next-btn-outer {
      margin-right: 80px;
    }
  }
}

@media (min-width: 1425px) {
  .introduction-background-img {
    height: auto;
    width: 100%;
  }
}

@include rwd(below, l) {
  .guide-container {
    .prev-btn-container {
      left: -3px;
    }

    .next-btn-container {
      right: -3px;
    }
  }
}

@include rwd(below, m) {
  .home-container {
    .home-text {
      justify-content: center;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .home-btn {
      margin-bottom: 20px;
    }
  }

  .guide-container {
    .guide-title {
      margin-top: 40px;
    }
  }

  .sign-links {
    margin-bottom: 40px;
  }
}

@include rwd(below, s) {
  .title-text {
    @include fontStyle(h5);
  }

  .home-container {
    .home-text {
      @include fontStyle(h4);
    }
  }

  .games-and-auctions {
    padding: 40px 0;

    .games-title {
      margin-bottom: 40px;
    }

    .games,
    .auctions {
      margin-top: 40px;
    }

    .auctions-title {
      padding-top: 80px;
    }

    .auctions-subtitle {
      margin-top: 12px;
      @include fontStyle(p);
    }

    .btn-more {
      margin: 40px 0;
    }

    .prev-btn-container,
    .next-btn-container,
    .prev-btn-container::after,
    .next-btn-container::before {
      min-width: 20px;
    }

    .prev-btn-container::after {
      transform: translate(20px, 0);
    }

    .next-btn-container::before {
      transform: translate(-20px, 0);
    }

    .prev-btn-outer,
    .next-btn-outer {
      display: none;
    }
  }

  .introduction-container {

    .agence-container,
    .explorer-container {
      align-self: center;
    }
  }

  .guide-container {
    .step-btn:not(:first-child)::before {
      width: calc((100vw - 160px) / 5);
    }

    .progress-text {
      display: none;
    }

    .guide-title {
      margin-bottom: 20px;
    }

    .content-paragraph {
      margin: 8px 0;
    }

    .content-title {
      margin-top: 12px;
      @include fontStyle(h6);
    }

    .content-btn {
      margin-top: 12px;
    }

    .guide-content {
      width: calc(100% - 32px);
      margin-top: 20px;
      align-self: center;
    }

    .prev-btn-container {
      left: -5px;
    }

    .next-btn-container {
      right: -5px;
    }
  }

  .sign-in-card,
  .sign-up-card {
    .card-action {
      &::after {
        content: '';
        margin-left: -20px;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 1px;
        width: calc(100% + 40px);
        background-color: $cardActionDividerColor;
      }
    }
  }
}