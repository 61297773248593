
$btnDisabledTextColor: rgba($white, 0.16);
$btnBorderColor: $blue900;
$btnActivatedTextColor: $blue400;
$btnActivatedBgColor: $turquoise400;
$btnInactivatedTextColor: $white;
$btnInactivatedBgColor: rgba($white, 0.16);
$btnHoverTextColor: $blue400;
$btnHoverBgColor:rgba($white, 0.5);

.pagination-container.pagination-container{
    display: flex;
    flex-direction: column;
    min-width: 208px;
    margin-top: 20px;
    margin-bottom: 60px;

    li {
        display: flex;       
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .pagination-item, 
    .pagination-firstpage, 
    .pagination-lastpage, 
    .pagination-left, 
    .pagination-right {
        width: 40px;
        height: 40px;
        padding: 0px 8px;
        min-width: 0px;
        font-size: 15px;
        
        background-color: $btnInactivatedBgColor;
        border: 1px solid $btnBorderColor;

        &:hover {
            color: $btnHoverTextColor;
            background-color: $btnHoverBgColor;

            .MuiSvgIcon-root {
                color: $btnHoverTextColor;
            }
        }
        .MuiSvgIcon-root {
            color: $btnInactivatedTextColor;
            width: 24px;
            height: 24px;   
        }

        &.disabled{
            .MuiSvgIcon-root {
                color: $btnDisabledTextColor;
            }
        }
    }
    .pagination-item, 
    .pagination-left, 
    .pagination-right {
        border-radius: 0px;
    }

    .pagination-firstpage {
        border-radius: 8px 0px 0px 8px;
    } 

    .pagination-lastpage {
        border-radius: 0px 8px 8px 0px;
    }

    .current-item.current-item.current-item {
        color: $btnActivatedTextColor;
        background-color: $btnActivatedBgColor;
    }
}

.pagination-selector, 
.pagination-pagejumper {
    align-self: center;
}

.pagination-selector {
    margin-bottom: 16px;
}

.pagination-pagejumper {
    .input-outer {
        flex-direction: row;
        align-items: center;
    }

    .input-inner {
        width: 48px;
        height: 40px;
        margin-left: 8px;
    }

    span {
        font-size: 15px;
        width: max-content;
        height: 20px;
    }
}

@include rwd(below, m){ 
    .pagination-container {
        .item-hide-medium {
            display: none;
        }
    }
}

@include rwd(below, s){ 
    .pagination-container {
        .item-hide-small {
            display: none;
        }
    }
}