$btnColor: $white;
$btnBgColor: $blue200;
$focusedBgColor: rgba($white, 0.16);
$pressedBgColor: $blue400;
$menuShadowColor: rgba($black, 0.5);
$dividerColor: $divider;
$ovalBgColor: $black;
$ovalBorderColor: $divider;
$ovalHoverColor: rgba($white, 0.8);

.btn.dropdown-btn,
.dropdown-menu {
  min-width: 148px;
  border-radius: 8px;
  padding: 0 8px 0 16px;
}

.btn.dropdown-btn:not(.outlined),
.dropdown-menu {
  border: none;
  color: $btnColor;
  background: $btnBgColor;
}

.btn.dropdown-btn,
li.dropdown-menuItem {
  @include fontStyle(400, 15px, 22px);
  transition: all 0.3s;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
}

.btn.dropdown-btn {
  position: relative;
  z-index: 1;
  &:after {
    border-radius: 8px;
    z-index: 2;
  }
  &:disabled {
    opacity: 0.16;
  }
  &:not(:disabled) {
    &.focused:not(.pressed):after {
      background: $focusedBgColor;
    }
  }
  &.oval {
    border: 2px solid $ovalBorderColor;
    border-radius: 20px;
    background: $ovalBgColor;
    color: $ovalBorderColor;
    padding: 0 8px 0 16px;
    font-weight: 600;
    min-width: 120px;
    &:after {
      border-radius: 20px;
    }
    &:not(:disabled) {
      &.pressed {
        color: $ovalBgColor;
        &:after {
          background: $ovalBorderColor;
        }
      }
      &:not(.pressed):hover {
        color: $ovalHoverColor;
        border-color: $ovalHoverColor;
        &:after {
          background: $ovalBgColor;
        }
      }
    }
  }
  .dropdown-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    font-size: inherit;
    position: relative;
    .arrow-down {
      display: flex;
      position: absolute;
      right: 0;
      margin-left: 8px;
    }
  }
  .icon,
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
}

.dropdown-popper {
  z-index: 500;
  box-shadow: 0 10px 30px $menuShadowColor;
  border-radius: 8px;
}

ul.dropdown-menu {
  &.oval {
    background: $ovalBgColor;
    border: 1px solid $ovalBorderColor;
    li.dropdown-menuItem {
      background: $ovalBgColor;
      font-weight: 600;
    }
  }
  li.dropdown-menuItem {
    padding: 8px 16px;
    z-index: 1;
    background-color: $btnBgColor;
    &:after {
      z-index: 2;
    }
    &.focused {
      background: $focusedBgColor;
    }
    &.pressed {
      background: $pressedBgColor;
    }
    &.dropdown-addItem-menuItem {
      padding: 6px 12px;
    }
    .dropdown-menuItem-label {
      display: flex;
      font-size: inherit;
      z-index: 3;
      .menuitem-text {
        margin: 0 4px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background-color: $dividerColor;
  }
}
