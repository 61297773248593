@import '../utils/main';
$topbarColor: $divider;
$topbarBgColor: $black;
$iconColor: $divider;
$btnTextColor: $white;

$selectorMenuBorderColor: $divider;
$selectorMenuShadowColor: rgba($black, 0.8);
$selectorItemColor: $white;
$selectorItemHoverBgColor: $divider;
$selectorItemSelectedBgColor: rgba($white, 0.16);
$naviIconBgColor: rgba($white, 0.3);

$linkHoverColor: rgba($white, 0.8);
$linkCurrentColor: $yellow400;
$linkCurrentHoverColor: $yellow200;
$naviBtnColor: $divider;
$linkNewAuctIconColor: $blue900;
$signinBtnBorderColor: $turquoise400;
$walletCoinsIconColor: $white;
$walletProfileIconBgColor: $divider;
$walletCurrentHoverColor: $yellow400;
$armorIconColor: gradient('eva');
$auctionIconBgColor: gradient('eva');
$accountIconHoverColor: $white;

$topbarHeight: 72px;
$topbarBgColor: $black;

$footerHeight: 160px;
$footerIconColor: $divider;
$footerTextColor: $divider;
$footerLinkTextColor: $yellow400;

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
}

.topbar {
  width: inherit;
  height: 72px;
  .selector-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 32px;
      color: $iconColor;
    }
  }
  .selector-menu-outer {
    position: absolute;
    top: 40px;
  }
  .selector-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    background: $topbarBgColor;
    .selector-item {
      display: flex;
      align-items: center;
      color: $selectorItemColor;
      cursor: pointer;
      .icon {
        fill: $selectorItemColor;
        margin-right: 4px;
      }
      .item-text {
        font-size: 15px;
        line-height: 20px;
      }
      &:hover {
        background-color: $selectorItemHoverBgColor;
      }
    }
    .divider {
      height: 1px;
      margin: 4px 0;
      background-color: $divider;
    }
  }
}

.topbar-outer {
  display: flex;
  justify-content: center;
  position: fixed;
  width: inherit;
  background-color: $topbarBgColor;
  z-index: 1000;
}

.topbar-content {
  display: flex;
  align-items: center;
  position: relative;
}

.topbar-logo-container {
  position: absolute;
  left: 0;
}

.armor-logo-link,
.auctions-logo-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.armor-logo-link {
  width: 40px;
  height: 48px;
  margin-left: -8px;
}

.navi-container {
  .selector-btn {
    color: $naviBtnColor;
    .btn-text {
      width: 100%;
      @include fontStyle(600, 20px, 24px);
      margin-left: 4px;
    }
    .icon {
      font-size: 24px;
      margin-right: 4px;
    }
    .icon-navi {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 40px;
      .circle,
      .long-line,
      .short-line {
        display: block;
        height: 4px;
        background-color: $naviIconBgColor;
      }
      .circle {
        width: 4px;
        border-radius: 4px;
        margin: 2px 4px;
      }
      .long-line {
        width: 18px;
        border-radius: 1px;
        margin: 2px 8px 2px 0;
      }
      .short-line {
        width: 12px;
        border-radius: 1px;
        margin: 2px 14px 2px 0;
      }
    }
  }
  .selector-menu {
    .selector-item {
      height: 48px;
      padding: 0 12px;
      color: $naviBtnColor;
      &.selector-item:hover {
        background-color: unset;
      }
      &:first-child {
        margin-top: 12px;
      }
      &:last-child {
        margin-bottom: 12px;
      }
      &.userInfo {
        &:hover {
          cursor: default;
          background-color: unset;
        }
      }
      .icon,
      .navi-text {
        transition: all 0.3s;
        margin: 0 4px;
      }
      .icon {
        font-size: 28px;
      }
    }
    .divider {
      display: flex;
      align-self: center;
      margin: 12px 0;
      width: calc(100% - 24px);
    }
  }
  .navi-link {
    .icon {
      color: $iconColor;
    }
    .navi-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      .icon{
        font-size: 32px;
        fill: $iconColor;
      }
    }
    &.link-newAuct {
      .navi-icon {
        margin: 0 4px;
        width: 28px;
        height: 28px;
        border-radius: 8px;
        background: $armorIconColor;
      }
      .icon {
        fill: $linkNewAuctIconColor;
        font-size: 16px;
      }
    }
    &:hover {
      .icon,
      .navi-text {
        fill: $linkHoverColor;
        color: $linkHoverColor;
      }
    }
    &.current {
      .icon,
      .navi-text {
        fill: $linkCurrentColor;
        color: $linkCurrentColor;
      }
    }
    &.current:hover {
      .icon,
      .navi-text {
        fill: $linkCurrentColor;
        color: $linkCurrentHoverColor;
      }
    }
  }
}

.topbar-info {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;

  .selector-btn {
    border-radius: 8px;
    color: $btnTextColor;
  }

  .info-btn-container{
    display: flex;
  }

  .arrow {
    top: 4px;
    position: absolute;
    right: calc(50% + 7px);
    .top-layer,
    .bottom-layer,
    .middle-layer {
      z-index: 999;
      position: absolute;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: $topbarBgColor;
      border-width: 0 7px 10px 7px;
    }
    .top-layer {
      top: 2px;
    }
    .middle-layer {
      border-bottom-color: $selectorMenuBorderColor;
    }
  }
  .selector-menu {
    width: 240px;
    border-radius: 8px;
    top: 14px;
    padding: 8px 0;
    border: 1px solid $selectorMenuBorderColor;
    box-shadow: 0px 10px 30px $selectorMenuShadowColor;
    box-sizing: border-box;
    .divider {
      width: calc(100% - 32px);
    }
  }

  .topbar-signin-btn{
    font-weight: 700;
    border-color: $signinBtnBorderColor;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  
  .create-auction-btn,
  .wallet,
  .account {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  .add-new-wallet-btn {
    padding: 0 20px;
    margin-right: 12px;
    @include fontStyle(600, 15px, 20px);
    &.eva:focus{
      color: inherit;
    }
    .icon {
      font-size: 24px;
      margin-right: 4px;
    }
  }

  .wallet {
    .selector-btn {
      width: 92px;
      background-color: initial;
      transition: all 0.5s;
      .icon {
        transition: all 0.1s;
      }

      .address {
        @include fontStyle(400, 12px, 17px);
        margin-left: 4px;
      }
    }
    .selector-menu-hover-area {
      top: 5px;
      width: 240px;
      height: 20px;
    }
    .middle-layer,
    .bottom-layer {
      opacity: 0;
      transition: all 0.5s;
    }
    .selector-menu {
      opacity: 0;
      transition: all 0.5s;
      right: calc(50% - 120px);
      max-height: calc(100vh - 152px);
      .icon {
        font-size: 24px;
      }
      .wallet-body {
        max-height: 490px;
        overflow: scroll;
        :last-child {
          .selector-item {
            margin-bottom: 0px;
          }
          .divider {
            display: none;
          }
        }
      }
      .selector-item-outer {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .selector-item {
        display: flex;
        height: 76px;
        padding: 0 16px;
        &.current {
          .wallet-text,
          .wallet-profile {
            color: $walletCurrentHoverColor;
            &.wallet-coins .icon {
              fill: $walletCurrentHoverColor;
            }
          }
        }
        .wallet-profile {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $walletProfileIconBgColor;
          min-width: 64px;
          height: 64px;
          border-radius: 64px;
          margin-right: 8px;
          .profile-img {
            fill: $topbarColor;
            height: 48px;
            width: 48px;
          }
        }
        .wallet-content {
          display: flex;
          flex-direction: column;
          .wallet-name {
            @include fontStyle(600, 14px, 19px);
          }
          .wallet-address,
          .wallet-coins {
            @include fontStyle(400, 12px, 17px);
          }
          .wallet-coins {
            display: flex;
            align-items: center;
            margin-top: 4px;
            .icon {
              width: 16px;
              height: 16px;
              fill: $walletCoinsIconColor;
              margin-right: 2px;
            }
          }
        }
      }
      .divider {
        display: flex;
        align-self: flex-end;
        position: relative;
        right: 16px;
        width: calc(100% - 104px);
        margin: 6px 0;
        background-color: $topbarColor;
      }
      .wallet-content,
      .wallet-footer {
        display: flex;
        flex-direction: column;
      }
      .wallet-footer {
        .selector-item {
          height: 24px;
          padding: 4px 16px;
        }
        .divider {
          margin-top: 6px;
          width: calc(100% - 32px);
        }
      }
    }
  }

  .account {
    &:hover:not(.selected) {
      .selector-btn {
        .icon {
          color: $accountIconHoverColor;
        }
      }
    }
    .selector-menu {
      right: -32px;
      .selector-item {
        height: 36px;
        padding: 0 15px;
        &.userInfo {
          &:hover {
            cursor: default;
            background-color: unset;
          }
        }
        .premium {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          height: 24px;
          width: 68px;
          color: $btnTextColor;
          background-color: $topbarColor;
          @include fontStyle(400, 12px, 17px);
          border-radius: 8px;
        }
      }
      .divider {
        display: flex;
        align-self: center;
      }
    }
  }

  .selected {
    .selector-menu,
    .bottom-layer,
    .middle-layer {
      display: flex;
      opacity: 1;
    }
    .selector-btn {
      background-color: $selectorItemSelectedBgColor;
      .icon {
        color: $topbarBgColor;
      }
    }
  }

  .create-auction-btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: $auctionIconBgColor;
    min-width: 0px;
    .icon {
      font-size: 20px;
      color: $topbarBgColor;
      transform: translate(1px, -1px);
    }
  }

  .layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.layout-wrapper {
  transition: top 0.1s;
  position: relative;
  height: 100%;
  flex: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $footerHeight;
  background-color: $topbarBgColor;
  .icon {
    fill: $footerIconColor;
  }
  .footer-navi {
    display: flex;
    justify-content: center;
    height: 40px;
    margin-bottom: 20px;
    .logo-link {
      margin: 0 40px;
    }
    .btn-icon {
      margin: 0 6px;
      .icon {
        height: 40px;
        width: 40px;
      }
    }
  }
  .language-logo-container {
    display: flex;
  }
  .footer-copyright {
    display: flex;
    justify-content: center;
  }
  .copyright-text,
  .copyright-link {
    font-size: 15px;
    line-height: 20px;
    color: $footerTextColor;
  }

  .copyright-link {
    color: $footerLinkTextColor;
  }
}

.game-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .selector-menu-outer {
    position: absolute;
    top: 56px;
  }
  .arrow {
    top: 4px;
    position: absolute;
    right: calc(50% + 7px);
    .top-layer,
    .bottom-layer,
    .middle-layer {
      z-index: 999;
      position: absolute;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: $topbarBgColor;
      border-width: 0 7px 10px 7px;
    }
    .top-layer {
      top: 2px;
    }
    .middle-layer {
      border-bottom-color: $selectorMenuBorderColor;
    }
  }
  .selector-menu {
    width: 160px;
    border-radius: 8px;
    top: 14px;
    right: -32px;
    border: 1px solid $selectorMenuBorderColor;
    box-shadow: 0px 10px 30px $selectorMenuShadowColor;
    box-sizing: border-box;
    .selector-item {
      height: 36px;
      color: $selectorItemColor;
      &:first-child {
        margin-top: 6px;
      }
      &:last-child {
        margin-bottom: 6px;
      }
    }
  }
}

@include rwd(above, s) {
  .topbar-content,
  .layout-wrapper {
    width: calc(100% - 40px);
    max-width: 1136px;
  }
}

@include rwd(above, m) {
  .wallet,
  .account {
    height: 40px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  .armor-logo-link,
  .navi-container .selector-btn {
    display: none;
  }
  .topbar-content {
    justify-content: center;
  }
  .topbar-navi,
  .navi-list {
    display: flex;
    position: relative;
    max-width: 1136px;
  }
  .topbar-info {
    .no-signin,
    .no-wallet {
      display: none;
    }
  }

  .navi-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 64px;
    height: 60px;
    margin: 6px;
    &.link-newAuct {
      display: none;
    }
    .icon {
      font-size: 32px;
    }
    .navi-text {
      @include fontStyle(400, 12px, 17px);
      color: $topbarColor;
    }
  }
}

@include rwd(above, l) {
  .account,
  .wallet{
    .selector-btn{
      height: 100%;
      width: 100%;
    }
  }
  .account{
    width: 64px;
    .icon.icon-expandMore {
      font-size: 24px;
    }
  }
}

@include rwd(below, l) {
  .topbar-info {
    .wallet,
    .account {
      width: 40px;
    }
    .selector-btn {
      height: 32px;
      width: 32px;
      .address,
      .icon-expandMore {
        display: none;
      }
    }
    .selected {
      .selector-btn{
        background: unset;
      }
      &.wallet {
        .selector-btn .icon{
          color: $walletCurrentHoverColor;
        }
      }
      &.account {
        .selector-btn .icon{
          color: $selectorItemHoverBgColor;
        }
      }
    }
  }
}

@include rwd(below, m) {
  .auctions-logo-outer {
    display: none;
  }
  .topbar {
    height: 48px;
    .selector-btn {
      height: 100%;
    }
    .selector-menu-outer{
      top: 35px;
    }
    .selector-menu {
      position: fixed;
      width: 100vw;
      top: 48px;
      left: 0;
    }
    .game-tab.game-tab {
      display: none;
    }
  }
  .topbar-logo-container {
    position: relative;
  }
  .navi-container {
    display: flex;
    position: relative;
    height: 48px;
    .navi-list {
      display: none;
    }
  }

  .topbar-info {
    .info-btn-container {
      display: none;
    }
    .wallet.wallet,
    .account.account {
      width: fit-content;
      margin-right: 0px;
      .selector-menu {
        position: fixed;
        width: 100vw;
        top: 48px;
        border-radius: unset;
      }
      .selector-btn {
        position: relative;
        height: 48px;
        width: 44px;
      }
    }

    .create-auction-btn {
      display: none;
    }
  }
}

@include rwd(below, s) {
  .topbar-content,
  .layout-wrapper {
    width: calc(100% - 24px);
  }
  .armor-logo-link {
    margin-left: -2px;
  }
  .footer {
    height: 294px;
    .footer-navi {
      height: 152px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .language-logo-container {
      height: 96px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
    }
  } 
  .footer-copyright {
    flex-direction: column;
    align-items: center;
  }
}

@include rwd(above, m) {
  .navi-container{
    .mobile-menu-dropdown {
      display: none;
    }
  }
}