@import './utils/main';

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: $white;
  background: $blue900;
}
