$noDataTitleColor: $yellow400;

.no-data-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.no-data-title-container {
  margin-top: 60px;
  display: flex;
  align-items: center;
  .no-data-title {
    @include fontStyle(600, 24px, 36px);
    margin-right: 10px;
    color: $noDataTitleColor;
  }
}

.no-data-description-container {
  margin: 20px 0;
  .no-data-description {
    width: 600px;
    text-align: center;
  }
}

.no-data-img-container {
  .no-data-img {
    height: 160px;
    width: 160px;
  }
}

.no-data-btn-container {
  margin-top: 16px;
  margin-bottom: 80px;
  .no-data-btn {
    @include fontStyle(600, 20px, 30px);
  }
}
