/* svg color */
@mixin svgIconColor($color) {
  svg.icon {
    *:not(title):not(g):not(.fill-none) {
      fill: $color;
      transition: all 0.3s;
    }
  }
}
/**/

/* media query */
@mixin rwd($direction, $size, $size2: null) {
  @each $name, $width in $breakpoint {
    @if ($size == $name) {
      @if ($direction == above) {
        @media (min-width: $width) {
          @content;
        }
      }
      @if ($direction == below) {
        @media (max-width: #{$width - 1}) {
          @content;
        }
      }
      @if ($direction == between) {
        @if ($size and $size2) {
          @media all and (min-width: map-get($map: $breakpoint, $key: $size)) and (max-width: map-get($map: $breakpoint, $key: $size2) - 1px) {
            @content;
          }
        }
      }
    }
  }
}
/**/

/* font style */
@mixin fontStyle($weight: initial, $size: initial, $height: initial) {
  font-weight: 600;
  letter-spacing: 0;
  @if ($weight == h1) {
    font-size: 60px;
    line-height: 82px;
  } @else if ($weight == h2) {
    font-size: 48px;
    line-height: 66px;
  } @else if ($weight == h3) {
    font-size: 40px;
    line-height: 56px;
  } @else if ($weight == h4) {
    font-size: 32px;
    line-height: 44px;
  } @else if ($weight == h5) {
    font-size: 24px;
    line-height: 32px;
  } @else if ($weight == h6) {
    font-size: 20px;
    line-height: 28px;
  } @else if ($weight == h7) {
    font-size: 15px;
    line-height: 22px;
  }@else if ($weight == p) {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
  } @else {
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
  }
}
/**/
