$shadowColor: rgba($black, 0.5);
$darkColor: $white;
$darkBgColor: $black;
$primaryColor: $blue900;
$primaryBgColor: $yellow400;
$primaryIconColor: $yellow700;
$dangerColor: $black;
$dangerBgColor: $red400;
$dangerIconColor: $red700;

.tooltip-container {
  z-index: 5; // Need to be greater than dropdown-label's z-index
}
.tooltip {
  padding: 0 8px;
  max-width: 240px;
  position: relative;
  @include fontStyle(600, 14px, 21px);
  box-shadow: 0 10px 30px $shadowColor;
  transition: all 0;
  pointer-events: unset;
  &.input-tooltip {
    pointer-events: unset;
    min-height: 40px;
    max-width: 100%;
    padding: 10px 12px 10px 38px;
    border-radius: 8px;
    box-sizing: border-box;
  }
  &.dark {
    color: $darkColor;
    background: $darkBgColor;
  }
  &.primary {
    color: $primaryColor;
    background: $primaryBgColor;
    .icon.tooltip-icon {
      color: $primaryIconColor;
    }
  }
  &.danger {
    color: $dangerColor;
    background: $dangerBgColor;
    .icon.tooltip-icon {
      color: $dangerIconColor;
    }
  }
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
  }
  &.MuiTooltip-tooltipPlacementTop {
    top: -3px;
    &:after {
      left: calc(50% - 6px);
      bottom: -8px;
      border-width: 8px 6px 0 6px;
      border-color: $darkBgColor transparent transparent transparent;
    }
  }
  &.right {
    &:after {
      top: calc(50% - 8px);
      left: -12px;
      border-width: 8px 13px 8px 0;
    }
    &.dark:after {
      border-color: transparent $darkBgColor transparent transparent;
    }
    &.primary:after {
      border-color: transparent $primaryBgColor transparent transparent;
    }
    &.danger:after {
      border-color: transparent $dangerBgColor transparent transparent;
    }
  }
  &.bottom {
    &:after {
      left: calc(50% - 8px);
      border-width: 0 8px 13px 8px;
      top: -12px;
    }
    &.dark:after {
      border-color: transparent transparent $darkBgColor transparent;
    }
    &.primary:after {
      border-color: transparent transparent $primaryBgColor transparent;
    }
    &.danger:after {
      border-color: transparent transparent $dangerBgColor transparent;
    }
  }
  &.top {
    &:after {
      left: calc(50% - 8px);
      bottom: -12px;
      border-width: 13px 8px 0 8px;
    }
    &.dark:after {
      border-color: $darkBgColor transparent transparent transparent;
    }
    &.primary:after {
      border-color: $primaryBgColor transparent transparent transparent;
    }
    &.danger:after {
      border-color: $dangerBgColor transparent transparent transparent;
    }
  } 
}

.icon.tooltip-icon {
  top: 8px;
  left: 8px;
  bottom: unset;
  margin-right: 6px;
  opacity: 1;
  z-index: 5;
  position: absolute;
}

.hidden.hidden {
  visibility: hidden;
}
