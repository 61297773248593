$defaultColor: $white;
$placeholderColor: $divider;
$backgroundColor: $blue400;
$hoverOverlayBgColor: rgba($black, 0.16);
$primaryColor: $yellow400;
$dangerColor: $red400;
$btnBgColor: rgba($white, 0.16);
$btnHoverColor: $blue900;
$btnHoverBgColor: rgba($white, 0.5);
$manaBgColor: rgba($white, 0.5);
$mobileSearchBgColor: $black;
$searchColor: rgba($white, 0.16);
$searchTextColor: $divider;
$searchBgColor: $blue400;

/* input base */
.input-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s;
  &.disabled {
    opacity: 0.16;
    .icon.input-icon {
      opacity: 0;
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.input-label {
  @include fontStyle(600, 14px, 21px);
  margin-bottom: 4px;
  transition: all 0.3s;
}
.input-inner {
  border-radius: 8px;
  background: $backgroundColor;
  height: 40px;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  &:not(.isFocus).isHover {
    .input-overlay:after {
      background-color: $hoverOverlayBgColor;
    }
  }
}
.input-overlay {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    transition: all 0.3s;
  }
}
.input {
  width: 100%;
  height: 100%;
  color: $defaultColor;
  padding: 8px 10px;
  background: transparent;
  border-radius: inherit;
  font-size: 15px;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: all 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  @include fontStyle(600, 14px, 19px);
  &::placeholder {
    color: $placeholderColor;
  }
  &:focus,
  &.with-helper {
    border-color: $primaryColor;
  }

  &.password:focus {
    padding-right: 40px;
  }

  &.with-helper:not(:focus) {
    padding-right: 40px;
    ~ .help-icon {
      opacity: 1;
      z-index: 1;
    }
  }

  &.error:not(:focus) {
    padding-right: 40px;
    border-color: $dangerColor;
    ~ .error-icon {
      opacity: 1;
      z-index: 1;
    }
  }
  &.input:-webkit-autofill {
    background-color: transparent !important;
  }
}

.icon.input-icon {
  position: absolute;
  right: 10px;
  bottom: 8px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  &.help-icon {
    color: $primaryColor;
  }
  &.error-icon {
    color: $dangerColor;
  }
}
/**/
/* password input */
.btn.btn-showPassword {
  color: $primaryColor;
  position: absolute;
  right: 2px;
  bottom: 0;
  width: 40px;
  height: 40px;
  transition: opacity 0.3s, transform 0s linear 0.3s;
  transform: scale(0);
  opacity: 0;
}
.input {
  &:not(.helper):focus,
  &.helper {
    ~ .btn-showPassword {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.3s, transform 0s linear 0s;
    }
  }
}
/**/
/* number input */
.input-inner {
  .mana-container {
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    bottom: 8px;
    .icon {
      fill: $manaBgColor;
      margin-right: 4px;
    }
    .mana-text {
      @include fontStyle(p);
      font-weight: 600;
      letter-spacing: 0px;
      color: $manaBgColor;
    }
  }
}
.input-number-outer {
  position: relative;
  display: flex;
  &.input-number-with-operator-outer {
    .input-btn {
      width: 40px;
      height: 40px;
      border: none;
      color: $defaultColor;
      background: $btnBgColor;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: $btnHoverColor;
        background: $btnHoverBgColor;
      }
      &.prefix {
        border-radius: 8px 0 0 8px;
      }
      &.suffix {
        border-radius: 0 8px 8px 0;
      }
      .icon,
      .MuiSvgIcon-root {
        transform: translateY(1px);
      }
    }
    .input-inner {
      width: 48px;
      border-radius: 0;
    }
    .input {
      text-align: center;
    }
  }
}
/**/
/* with-dropdown input */
.with-dropdown-wrapper {
  display: flex;
  align-items: flex-end;
  width: 250px;
  .input-inner {
    border-radius: 8px 0 0 8px;
  }
  .btn.dropdown-btn.dropdown-btn {
    border-radius: 0 8px 8px 0;
    min-width: fit-content;
    background: $btnBgColor;
    font-weight: 600;
    margin-bottom: 16px;
    &:after {
      display: none;
    }
    &:not(:disabled) {
      &:hover:not(.focused) {
        color: $btnHoverColor;
        background: $btnHoverBgColor;
      }
    }
  }
}
/**/
/* text area */
.input-textarea-outer {
  min-height: 100px;
  .input-inner {
    min-height: inherit;
  }
  .input {
    min-height: inherit;
    resize: none;
  }
}
/**/
/* resizable text area */
.input-resizable-textarea-outer {
  height: 100%;
  min-height: 40px;
  display: flex;
  flex: 1;
  .input-inner {
    min-height: 40px;
    height: 100%;
  }
  .input {
    position: relative;
    resize: none;
  }
  [contentEditable='true']:empty:before {
    content: attr(placeholder);
    color: $placeholderColor;
  }
}
/**/
/* search input*/
.search-container {
  display: flex;
  position: relative;
}

.search-input {
  transition: width 0.2s ease;
  height: 40px;
  width: 180px;
  padding-left: 12px;
  padding-right: 40px;
  border-radius: 8px;
  background: $searchBgColor;
  &.Mui-focused {
    width: 320px;
  }
  .MuiInputBase-input {
    color: $searchTextColor;
    font-size: 15px;
    line-height: 22px;
  }
} 

.search-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  right: 0px;
  cursor: pointer;
  color: $searchTextColor;
}

@include rwd(below, m) {
  .mobile-search-outer {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 48px;
    width: 100vw;
    background-color: $mobileSearchBgColor;
    padding-bottom: 12px;
    z-index: 999;
  }

  .search-container {
    width: calc(100% - 40px);
    .search-input {
      width: 100%;
      background-color: $searchColor;
    }
  }
}
@include rwd(below, s) {
  .search-container {
    width: calc(100% - 24px);
  }
}
/**/
