$labelBgColor: $divider;

$primaryColor: $yellow400;
$secondaryColor: $turquoise400;
$dangerColor: $red400;

$blueTextColor: $blue400;

.label {
  width: fit-content;
  height: 28px;
  display: flex;
  background: $labelBgColor;
  padding: 6px 4px 6px 6px;
  border-radius: 8px 0 0 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 0 14px 8px;
    border-color: transparent transparent transparent $labelBgColor;
  }
  .icon,
  .MuiSvgIcon-root {
    color: $blueTextColor;
    margin-right: 4px;
    font-size: 20px;
  }
  &.primary {
    background: $primaryColor;
    &:after {
      border-color: transparent transparent transparent $primaryColor;
    }
  }
  &.secondary {
    color: $blueTextColor;
    background: $secondaryColor;
    &:after {
      border-color: transparent transparent transparent $secondaryColor;
    }
  }
  &.danger {
    background: $dangerColor;
    &:after {
      border-color: transparent transparent transparent $dangerColor;
    }
  }
}
