@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

*:not(svg):not(.icon) {
  font-family: 'Open Sans';
}

.h1 {
  @include fontStyle(h1);
}
.h2 {
  @include fontStyle(h2);
}
.h3 {
  @include fontStyle(h3);
}
.h4 {
  @include fontStyle(h4);
}
.h5 {
  @include fontStyle(h5);
}
.h6 {
  @include fontStyle(h6);
}
p {
  @include fontStyle(p);
}
