.align-fix {
  transform: translateY(-1px);
}

.hide.hide {
  display: none;
}

/* override material-ui */
.MuiTouchRipple-root {
  display: none;
}

.MuiTooltip-popper.MuiTooltip-popper {
  pointer-events: initial;
  z-index: 5;
}
/**/
