$linkColor: $yellow400;
.no-result-container{
    margin: 80px 0 160px 0;
}
.no-result-img-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}
.no-result-img{
    height: 240px;
    width: 240px;
}
.no-result-img-text{
    margin-top: -40px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fontStyle(700, 30px, 45px);
}
.no-result-text-container{
    position: relative;
    display: flex;
    justify-content: center;
    @include fontStyle(400, 15px, 22px);
    .link-text{
        margin: 0 4px;
        color: $linkColor;
        cursor: pointer;
        padding: unset;
        text-transform: unset;
        min-width: unset;
    }
}