@function gradient($color, $deg: 180deg) {
  $startColor: map-get(
    $map: $gradStart,
    $key: $color,
  );
  $endColor: map-get(
    $map: $gradEnd,
    $key: $color,
  );
  @return linear-gradient($deg, $startColor, $endColor);
}

@function disabledColor($color) {
  @return rgba($color, 0.16);
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}
