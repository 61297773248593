$stepNormalColor: $blue200;
$stepIconNormalColor: $blue400;

$stepPrimaryColor: $yellow400;
$stepIconPrimaryColor: $black;

$stepSecondaryColor: $turquoise400;

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-btn {
  display: flex;
  position: relative;
  &.selected:not(:first-child) {
    &.primary::before {
      background-color: $stepPrimaryColor;
    }
    &.secondary::before {
      background-color: $stepSecondaryColor;
    }
  }
  &.primary.selected {
    .icon {
      fill: $stepIconPrimaryColor;
    }
  }
  &:not(:first-child)::before {
    content: '';
    align-self: center;
    width: 120px;
    height: 2px;
    background-color: $stepNormalColor;
  }
  .icon-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 28px;
    width: 28px;
    border-radius: 999em;
    background-color: $stepNormalColor;
    &.selected {
      &.primary {
        background-color: $stepPrimaryColor;
      }
      &.secondary {
        background-color: $stepSecondaryColor;
      }
    }
    .icon {
      position: relative;
      fill: $stepIconNormalColor;
      height: 20px;
      width: 20px;
    }
  }

  .progress-text-outer {
    position: absolute;
    display: flex;
    top: 32px;
    right: -36px;
    .progress-text {
      position: relative;
      text-align: center;
      width: 100px;
      height: 38px;
      color: $stepNormalColor;
      @include fontStyle(600, 14px, 19px);
      &.selected {
        &.primary {
          color: $stepPrimaryColor;
        }
        &.secondary {
          color: $stepSecondaryColor;
        }
      }
    }
  }
}


@include rwd(below, l) {
  .progress-btn {
    &:not(:first-child)::before {
      width: 112px;
    }
  }
}

@include rwd(below, m) {
  .progress-btn {
    &:not(:first-child)::before {
      width: 72px;
    }
  }
}

@include rwd(below, s) {
  .progress-btn {
    &:not(:first-child)::before {
      width: 39px;
    }
  }
}

@include rwd(below, xs) {
  .progress-btn {
    &:not(:first-child)::before {
      width: 29px;
    }
  }
}