$defaultColor: $white;
$defaultIconColor: $divider;
$defaultBorderColor: $divider;
$defaultHoverColor: $divider;
$defaultHoverBgColor: rgba($white, 0.1);
$defaultPressedColor: $divider;
$defaultPressedBgColor: rgba($white, 0.2);

$primaryColor: $yellow400;
$primaryHoverColor: $yellow200;
$primaryHoverBgColor: rgba($yellow400, 0.1);
$primaryPressedColor: $yellow700;
$primaryShadowColor: rgba($yellow400, 0.5);

$secondaryColor: $turquoise400;
$secondaryHoverColor: $turquoise200;
$secondaryHoverBgColor: rgba($turquoise400, 0.1);
$secondaryPressedColor: $turquoise700;
$secondaryPressedBgColor: rgba($turquoise400, 0.2);
$secondaryShadowColor: rgba($turquoise400, 0.5);

$dangerColor: $red400;
$dangerHoverColor: $red200;
$dangerHoverBgColor: rgba($red400, 0.1);
$dangerPressedColor: $red700;
$dangerShadowColor: rgba($red400, 0.5);

$evaBgColor: gradient('eva', 90deg);
$evaIconColor: $blue400;
$evaShadowColor: rgba($turquoise400, 0.5);

$containedDisabledColor: rgba($white, 0.1);

$lightTextColor: $white;
$blueTextColor: $blue400;

$discordBgColor: $turquoise700;
$discordHoverBgColor: #7289da;

button.btn,
a.btn {
  width: max-content;
  min-width: 100px;
  height: 40px;
  padding: 0 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  box-sizing: border-box;
  transition: all 0.3s;
  font-family: inherit;
  z-index: 1;
  cursor: pointer;
  &.large {
    min-width: 120px;
    padding: 0 32px;
    height: 60px;
    font-size: 20px;
  }
  span {
    width: fit-content;
    transition: all 0s;
    z-index: 2;
  }
  .icon,
  .MuiSvgIcon-root {
    font-size: inherit;
    color: inherit;
  }
}

.btn.rounded {
  border-radius: 20px;
  &.large {
    border-radius: 30px;
  }
}

.btn.prefix,
.btn.suffix {
  padding: 0 12px 0 8px;
  &.rounded {
    padding: 0 16px 0 12px;
  }
  .icon,
  .MuiSvgIcon-root {
    font-size: 24px;
    margin-right: 4px;
  }
}

.btn.contained {
  background: $defaultColor;
  &:hover {
    background: $defaultHoverColor;
  }
  &:active {
    background: $defaultPressedColor;
  }
  &:disabled:disabled,
  &.disabled.disabled {
    color: $containedDisabledColor;
    background: $containedDisabledColor;
    .icon,
    .MuiSvgIcon-root {
      color: $containedDisabledColor;
    }
  }
  &.primary {
    background: $primaryColor;
    &:hover {
      background: $primaryHoverColor;
      box-shadow: 0px 0px 20px $primaryShadowColor;
    }
    &:active {
      background: $primaryPressedColor;
      box-shadow: none;
    }
  }
  &.secondary {
    color: $blueTextColor;
    background: $secondaryColor;
    &:hover {
      background: $secondaryHoverColor;
      box-shadow: 0px 0px 20px $secondaryShadowColor;
    }
    &:active {
      color: $lightTextColor;
      background: $secondaryPressedColor;
      box-shadow: none;
    }
  }
  &.danger {
    background: $dangerColor;
    &:hover {
      background: $dangerHoverColor;
      box-shadow: 0px 0px 20px $dangerShadowColor;
    }
    &:active {
      color: $lightTextColor;
      background: $dangerPressedColor;
      box-shadow: none;
    }
  }
  &.eva {
    color: $lightTextColor;
    background: $evaBgColor;
    .icon,
    .MuiSvgIcon-root {
      color: $evaIconColor;
    }
    &:hover {
      box-shadow: 0px 0px 20px $evaShadowColor;
    }
    &:active {
      color: $blueTextColor;
      box-shadow: none;
    }
  }
}

.btn.outlined {
  color: $defaultColor;
  border: 2px solid $defaultBorderColor;
  &:hover {
    background: $defaultHoverBgColor;
  }
  &:active {
    background: $defaultPressedBgColor;
  }
  &:disabled,
  &.disabled {
    color: disabledColor($defaultColor);
    border-color: disabledColor($defaultColor);
  }
  &.primary {
    color: $primaryColor;
    border-color: $primaryColor;
    &:hover {
      color: $primaryHoverColor;
      border-color: $primaryHoverColor;
      background: $primaryHoverBgColor;
    }
    &:active {
      color: $primaryPressedColor;
      border-color: $primaryPressedColor;
      background: $primaryHoverBgColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($primaryColor);
      border-color: disabledColor($primaryColor);
    }
  }
  &.secondary {
    color: $secondaryColor;
    border-color: $secondaryColor;
    &:hover {
      color: $secondaryHoverColor;
      border-color: $secondaryHoverColor;
      background: $secondaryHoverBgColor;
    }
    &:active {
      color: $secondaryPressedColor;
      border-color: $secondaryPressedColor;
      background: $secondaryHoverBgColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($secondaryColor);
      border-color: disabledColor($secondaryColor);
    }
  }
  &.danger {
    color: $dangerColor;
    border-color: $dangerColor;
    &:hover {
      color: $dangerHoverColor;
      border-color: $dangerHoverColor;
      background: $dangerHoverBgColor;
    }
    &:active {
      color: $dangerPressedColor;
      border-color: $dangerPressedColor;
      background: $dangerHoverBgColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($dangerColor);
      border-color: disabledColor($dangerColor);
    }
  }
}

.btn.text {
  color: $defaultColor;
  font-weight: 600;
  &.prefix,
  &.suffix {
    font-size: 16px;
    font-weight: 500;
  }
  .icon,
  .MuiSvgIcon-root {
    color: $primaryColor;
  }
  &:hover {
    color: $primaryColor;
    background-color: unset;
    .icon,
    .MuiSvgIcon-root {
      color: $primaryColor;
    }
  }
  &:active {
    color: $primaryPressedColor;
    .icon,
    .MuiSvgIcon-root {
      color: $primaryPressedColor;
    }
  }
  &:disabled,
  &.disabled {
    color: disabledColor($defaultColor);
    .icon,
    .MuiSvgIcon-root {
      color: disabledColor($defaultColor);
    }
  }
  &.primary {
    color: $primaryColor;
    &:hover {
      color: $primaryHoverColor;
    }
    &:active {
      color: $primaryPressedColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($primaryColor);
    }
  }
  &.secondary {
    color: $secondaryColor;
    &:hover {
      color: $secondaryHoverColor;
    }
    &:active {
      color: $secondaryPressedColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($secondaryColor);
    }
  }
  &.danger {
    color: $dangerColor;
    &:hover {
      color: $dangerHoverColor;
    }
    &:active {
      color: $dangerPressedColor;
    }
    &:disabled,
    &.disabled {
      color: disabledColor($dangerColor);
    }
  }
}

.btn.icon {
  color: $defaultIconColor;
  min-width: unset;
  padding: 0;
  width: 24px;
  height: 24px;
  span {
    font-size: 24px;
  }
  &.large {
    min-width: unset;
    padding: 0;
    width: 40px;
    height: 40px;
    span {
      font-size: 40px;
    }
  }
  &:hover {
    color: $defaultColor;
  }
  &:active {
    color: $defaultPressedColor;
  }
  &:disabled:disabled,
  &.disabled.disabled {
    color: disabledColor($defaultColor);
  }
  &.primary {
    &:hover {
      color: $primaryColor;
    }
    &:active {
      color: $primaryPressedColor;
    }
  }
  &.secondary {
    &:hover {
      color: $secondaryColor;
    }
    &:active {
      color: $secondaryPressedColor;
    }
  }
  &.danger {
    &:hover {
      color: $dangerColor;
    }
    &:active {
      color: $dangerPressedColor;
    }
  }
}

.btn-group {
  display: flex;
  width: 100%;
  .group-item {
    display: flex;
    flex: 1;
    &:not(:last-of-type) {
      margin-right: 12px;
    }
  }
}

.btn-discord {
  background: $discordBgColor;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.5s;
  .chat-link {
    display: none;
  }
  &:hover {
    width: 150px;
    background-color: $discordHoverBgColor;
    .chat-icon {
      display: none;
    }
    .chat-link {
      display: inline-flex;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      color: $lightTextColor;
      text-align: start;
      min-width: 118px;
      max-height: 36px;
      padding: 0 12px 0 16px;
    }
    .chat-img {
      margin-right: 8px;
    }
  }
}

.btn.btn-arrow.btn-arrow {
  min-width: 24px;
  padding: 0;
  width: 24px;
  height: 24px;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid transparent;
    box-sizing: border-box;
    transform: rotate(45deg);
    transition: all 0.3s;
  }
  &.white,
  &.primary,
  &.secondary,
  &.danger {
    color: $defaultColor;
    border: none;
    background: transparent;
  }
  &.white {
    &:after {
      border-color: $defaultBorderColor;
    }
    &:hover:after {
      background: $defaultHoverBgColor;
    }
    &.focus:after {
      background: $defaultPressedBgColor;
    }
  }
  &.secondary {
    &:after {
      border-color: $secondaryColor;
    }
    &:hover:after {
      background: $secondaryHoverBgColor;
    }
    &.focus:after {
      background: $secondaryPressedBgColor;
    }
  }
  &:disabled,
  &.disabled {
    color: disabledColor($defaultColor);
    &:after {
      border-color: disabledColor($defaultColor);
    }
  }
  &.large {
    min-width: 48px;
    padding: 0;
    width: 48px;
    height: 48px;
    .icon,
    .MuiSvgIcon-root {
      font-size: 48px;
    }
  }
  .icon,
  .MuiSvgIcon-root {
    color: inherit;
    font-size: 24px;
  }
}

/* override Mui Button */
.MuiButton-root:hover {
  background-color: unset;
}
/**/
