$tabColor: $divider;
$tabSelectedColor: $white;
$tabBottomLineColor: $turquoise400;

.tabs {
  display: inline-flex;
  margin-top: 20px;

  @include rwd(below, s) {
    margin-top: 12px;
  }
}

.tab {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35px;
  color: $tabColor;
  @include fontStyle(600, 20px, 27px);
  &:not(:last-child) {
    margin-right: 20px;
  }
  &.selected {
    color: $tabSelectedColor;
    &::after {
      position: relative;
      content: '';
      height: 4px;
      background-color: $tabBottomLineColor;
    }
  }
}
