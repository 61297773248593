@import '../utils/main';

.breadcrumb-container {
  position: absolute;
  left: 0;
  top: 20px;
  .link-btn {
    display: flex;
    align-items: center;
  }
  .link-text {
    @include fontStyle(400, 15px, 22px);
  }
}

@include rwd(below, m) {
  .breadcrumb-container {
    top: 12px;
  }
}