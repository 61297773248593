/* palette */
$black: #11151d;
$white: #ffffff;
$divider: rgba($white, 0.3);

$blue900: #071e3d;
$blue800: #08254a;
$blue400: #1b4277;
$blue200: #35619e;
$blue100: #4a71a8;
$blue50: #6e8db9;

$yellow700: #b49f6a;
$yellow400: #f0ca6d;
$yellow200: #f8e2ad;

$turquoise700: #279d9d;
$turquoise400: #00d1cd;
$turquoise200: #68e8e8;

$red700: #b35050;
$red400: #ff7373;
$red200: #ff9696;

$gradStart: (
  'eva': #00d1cd,
  'gray': #7291bb,
);
$gradEnd: (
  'eva': #7550ed,
  'gray': #607ea7,
);
/**/

/* animation properties */
/**/

/* breakpoint */
$breakpoint: (
  xl: 1176px,
  l: 960px,
  m: 720px,
  s: 540px,
  xs: 360px,
);
/**/
