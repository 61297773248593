$cardBgColor: $blue200;

$primaryBtnColor: $yellow400;
$secondaryBtnColor: $turquoise400;
$dangerBtnColor: $red400;

$blueTextColor: $blue400;

$dropdownBgColor: $blue400;
$dropdownHoverOverlayColor: rgba($black, 0.16);

.row,
.column {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  &.align-center {
    justify-content: center;
  }
}

.row {
  flex-direction: row;
  width: calc(100% + 16px);
  min-width: 320px; // Prevent cards in auction list from shrinking too thin
}

.column {
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: $cardBgColor;
  padding: 28px 32px 32px 32px;
  border-radius: 16px;
  box-sizing: border-box;
}

.form-card {
  margin: 20px 0 40px 0;
  width: 360px;
}

.info-card {
  margin: 80px 0;
  max-width: 420px;
  width: 100%;
  .card-title {
    margin-bottom: 20px;
  }
  .card-action {
    margin-top: 20px;
  }
}

.progress-card {
  margin: 180px 0 80px 0;
  max-width: 420px;
  min-width:280px;
  width: 100%;
}

.card-title {
  display: flex;
  align-self: center;
  margin-bottom: 16px;
  @include fontStyle(h5);
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  @include fontStyle(p);
  letter-spacing: 0;
}

.card-action {
  display: flex;
  margin-top: 16px;
  .btn {
    width: 100%;
    &:not(:last-child) {
      margin-right: 12px;
    }
    &.text {
      &:hover {
        &.primary {
          background: rgba($primaryBtnColor, 0.2);
        }
        &.secondary {
          background: rgba($secondaryBtnColor, 0.2);
        }
        &.danger {
          background: rgba($dangerBtnColor, 0.2);
        }
      }
      &:focus {
        &.primary {
          color: $blueTextColor;
          background: rgba($primaryBtnColor, 0.4);
        }
        &.secondary {
          color: $blueTextColor;
          background: rgba($secondaryBtnColor, 0.4);
        }
        &.danger {
          color: $blueTextColor;
          background: rgba($dangerBtnColor, 0.4);
        }
      }
    }
    .MuiButton-label {
      @include fontStyle(700, 15px, 22px);
    }
  }
}

.card .btn.dropdown-btn {
  &:not(.outlined) {
    background: $dropdownBgColor;
  }
  &:not(:disabled):hover:not(:focus):after {
    background: $dropdownHoverOverlayColor;
  }
}

@include rwd(above, s) {
  .row {
    margin: 0 -8px;
  }
  @for $i from 1 through 12 {
    .column-s-#{$i} {
      padding: 0 8px;
      $gap: calc(16px * (#{$i} - 1));
      $columnWidth: calc((100% - 192px) / 12 * #{$i});
      width: calc(#{$columnWidth} + #{$gap});
    }
  }
}

@include rwd(above, m) {
  @for $i from 1 through 12 {
    .column-m-#{$i} {
      padding: 0 8px;
      $gap: calc(16px * (#{$i} - 1));
      $columnWidth: calc((100% - 192px) / 12 * #{$i});
      width: calc(#{$columnWidth} + #{$gap});
    }
  }
  .form-card {
    margin: 80px 0;
  }
}

@include rwd(above, l) {
  @for $i from 1 through 12 {
    .column-l-#{$i} {
      padding: 0 8px;
      $gap: calc(16px * (#{$i} - 1));
      $columnWidth: calc((100% - 192px) / 12 * #{$i});
      width: calc(#{$columnWidth} + #{$gap});
    }
  }
}

@include rwd(above, xl) {
  @for $i from 1 through 12 {
    .column-xl-#{$i} {
      padding: 0 8px;
      $gap: calc(16px * (#{$i} - 1));
      $columnWidth: calc((100% - 192px) / 12 * #{$i});
      width: calc(#{$columnWidth} + #{$gap});
    }
  }
}

@include rwd(below, s) {
  .row {
    margin: 0 -6px;
  }
  .card {
    padding: 16px 20px 20px 20px;
  }
  @for $i from 1 through 4 {
    .column-xs-#{$i} {
      padding: 0 6px;
      $gap: calc(12px * (#{$i} - 1));
      $columnWidth: calc((100% - 48px) / 4 * #{$i});
      width: calc(#{$columnWidth} + #{$gap});
    }
  }
}
